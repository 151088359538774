dmx.Component('background-video', {

    tag: 'div',

    initialData: {
        paused: false
    },

    attributes: {
        src: {
            type: String,
            default: null
        }
    },

    methods: {
        play: function() {
            this.video.play();
        },

        pause: function() {
            this.video.pause();
        },

        toggle: function() {
            if (this.video.paused) {
                this.video.play();
            } else {
                this.video.pause();
            }
        }
    },

    render: function(node) {
        this.$parse();

        var videoWrapper = document.createElement('div');
        var video = document.createElement('video');
        video.src = this.props.src;
        video.playsinline = true;
        video.autoplay = true;
        video.muted = true;
        video.loop = true;

        video.setAttribute('muted', '');
        video.setAttribute('playsinline', '');

        video.addEventListener('play', this.updateData.bind(this));
        video.addEventListener('pause', this.updateData.bind(this));

        videoWrapper.style.setProperty('position', 'absolute');
        videoWrapper.style.setProperty('width', '100%');
        videoWrapper.style.setProperty('height', '100%');
        videoWrapper.style.setProperty('overflow', 'hidden');

        video.style.setProperty('position', 'absolute');
        video.style.setProperty('width', 'auto');
        video.style.setProperty('height', 'auto');
        video.style.setProperty('min-width', '100%');
        video.style.setProperty('min-height', '100%');
        video.style.setProperty('top', '50%');
        video.style.setProperty('left', '50%');
        video.style.setProperty('transform', 'translate(-50%, -50%)');

        var wrapper = document.createElement('div');

        wrapper.style.setProperty('position', 'relative');
        node.style.setProperty('position', 'relative');

        node.parentNode.insertBefore(wrapper, node);
        videoWrapper.appendChild(video);
        wrapper.appendChild(videoWrapper);
        wrapper.appendChild(node);

        this.video = video;
    },

    updateData: function() {
        this.set('paused', this.video.paused);
    }

});
